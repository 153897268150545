import React from "react"
import '../styles/blue-section.scss'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const Signup = () => {
  const intl = useIntl()
  return (
    <section className="blue-section signup">
      <h2>
        <FormattedMessage id="create_account" />
      </h2>
      <form className="blue-form">
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.email" })} />
        <input type="password" className="inverse" placeholder={intl.formatMessage({ id: "forms.password" })} />
        <input type="password" className="inverse" placeholder={intl.formatMessage({ id: "forms.password_repeat" })} />
        <select className="inverse">
          <option>{intl.formatMessage({ id: "forms.state" })}</option>
        </select>
        <button className="vbtn vbtn--gradient" style={{margin: '15px 0 0 0'}}><FormattedMessage id="buttons.create" /></button>
      </form>
    </section>
  )
};

export default Signup