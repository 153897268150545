import React from "react"
import '../styles//white-section.scss'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const Login = () => {
  const intl = useIntl()
  return (
    <section className="white-section">
      <div className="container page-content">
        <div className="row">
          <div className="col-md-5">
            <h2>
              Login
            </h2>
            <form>
              <input type="text" placeholder={intl.formatMessage({ id: "forms.email" })} />
              <input type="password" placeholder={intl.formatMessage({ id: "forms.password" })} />
              <button className="vbtn vbtn--gradient" style={{margin: '15px 0 0 0'}}><FormattedMessage id="buttons.login" /></button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Login