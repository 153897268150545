import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Login from "../components/Login"
import Signup from "../components/Signup"

const LoginPage = () => {
  return (
    <Layout contentType="internal">
      <SEO title="Login" />
      <Login />
      <Signup />
    </Layout>
  )
};

export default LoginPage